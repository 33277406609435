<template>
    <div :id="idContainer" class="mapBox"></div>
 </template>
 <script>
 import L from "leaflet"
 import "leaflet/dist/leaflet.css"
 L.Popup.prototype._animateZoom = function (e) {
  if (!this._map) {
    return
  }
  var pos = this._map._latLngToNewLayerPoint(this._latlng, e.zoom, e.center),
  anchor = this._getAnchor()
  L.DomUtil.setPosition(this._container, pos.add(anchor))
}

 export default {
     emits:['mapEvent'],
     data() {
         return{
             map:null,
             point:null,
             image:null,
         }        
     },
     props:{
        idContainer:{
            type:String,
            default:'map'
        },
     },
     mounted(){
        
     },
     methods:{
        init(url,DrawPoints){
            if(this.image){
                this.image.setUrl(url)
                return
            }
            let _thisFun = this
            if(typeof(_thisFun.map) != 'undefined' && _thisFun.map != null){
                _thisFun.map.remove();
            }
            let _tmpIMG = new Image();
            _tmpIMG.src = url;
            _tmpIMG.onload = function() {
                let _tmpIMG_width = _tmpIMG.width;
                let _tmpIMG_height = _tmpIMG.height;
                _thisFun.map = new L.Map(_thisFun.idContainer, {
                    zoomControl:true,
                    attributionControl: false,
                    crs: L.CRS.Simple,
                    maxZoom : 5,
                    minZoom : -5,
                    center : [ $('#'+_thisFun.idContainer).width() / 2, $('#'+_thisFun.idContainer).height() / 2 ]
                });
                let southWest = _thisFun.map.unproject([0,0],0);
                let northEast = _thisFun.map.unproject([_tmpIMG_width,-_tmpIMG_height],0);
                //地图边界
                let bounds = new L.LatLngBounds(southWest, northEast);
                //自定义底图
                this.image = L.imageOverlay(_tmpIMG.src, bounds).addTo(_thisFun.map); 
                _thisFun.map.setMaxBounds(bounds);
                _thisFun.map.fitBounds(bounds);
                _thisFun.map.doubleClickZoom.disable();  //禁止双击放大地图
                _thisFun.map.on('click', function(e){
                    console.log(e.latlng)
                })
                _thisFun.Daw(DrawPoints)
            }
        },
        Daw(data){
            let myIcon = L.icon({
                iconUrl: require('../../assets/siteImg/devGreen.png'),
                iconSize: [26, 20],
                iconAnchor: [13, 10],
                popupAnchor: [0, -10],
            });
            for(let i = 0; i<data.length; i++){
                if(data[i].type == 'polygon'){
                    var polygon = L.polygon(data[i].points,{
                        userData:data[i]
                    }).addTo(this.map).on('dblclick', (e)=>{
                        this.$emit('mapEvent',e)
                    }).on('click', (e)=>{
                        this.$emit('mapEvent',e)
                    })
                }else if(data[i].type == 'maker'){
                    let string = `<div style="font-size:16px">
                    <div style="font-size:20px; color:#A15728;margin-bottom:10px">${data[i].name}</div>
                    状态：${data[i].deviceStatus}<br/>`
                    let el = data[i].deviceParamsVal
                    for(let key in el){
                        let par = el[key]
                        string += `${par.envirParamName}:${par.envirParamValue}${par.envirDataUnit}<br/>` 
                    }
                    string+=`</div>`
                    let marker =  L.marker(data[i].points,{
                        icon: myIcon,
                        opacity:1,
                        data:data[i]
                    }).bindPopup(
                        string
                    ).addTo(this.map).on('click', (e)=>{
                        this.$emit('mapEvent',e)
                    })
                }
            }     
        },
     }
 }
 </script>
 <style lang="scss" scoped>
.mapBox{
    width: 100%;
    height: 100%;
 }
 </style>