<template>
    <div class="pageBox">
        <div class="topBox">
            <div class="TB-left">
                <div class="titleBox">
                    <img src="../../assets/siteImg/title.png">
                    {{ museumName }}
                </div>
                <div class="titleMsgBox">
                    <mapMode ref="mapMode" @mapEvent="mapEvent"/>
                </div>
            </div>
            <div class="TB-right">
                <div class="weather">
                    <iframe width="100%" height="100%" frameborder="0" scrolling="no" hspace="0" :src="siteData.weather_url"></iframe>
                </div>
                <div class="textBox">
                    <div>
                    Co：{{ weatherData.co }} mg/m³<br/>
                    O₃：{{ weatherData.o3 }} μg/m³<br/>
                    No₂：{{ weatherData.no2 }} μg/m³<br/>
                    So₂：{{ weatherData.so2 }} μg/m³<br/>
                    PM2.5：{{ weatherData.pm2_5 }}<br/>
                    PM10:{{ weatherData.pm10 }} μg/m³<br/>
                    空气质量：{{ weatherData.quality }}
                    </div>
                </div>
                <div class="msgBox">
                    <div>数据来源：中国天气网</div>
                    <div>更新时间：{{moment().format('YYYY-MM-DD HH:mm:ss')}}</div>
                </div>
            </div>
        </div>
        <div class="centerBox">
            <div class="TB-left CB-left">
                <div class="titleBox">
                    <img src="../../assets/siteImg/title.png">
                    站点监测指标覆盖情况
                </div>
                <div class="titleMsgBox CB-main">
                    <div class="main_Item">
                        <div class="itemT">
                            <div>今日已采样次数</div>
                            <div class="countBox">{{ dayCount }}</div>
                        </div>
                        <div class="itemC centerMsg">
                            昨日达标率
                        </div>
                        <div class="itemB centerMsg">
                            <div>当月达标率</div>
                        </div>
                    </div>
                    <div class="main_Item" v-for="(item, index) in baseDataList" :key="index">
                        <div class="itemT">
                            <div>{{ item.name }}</div>
                            <div><span class="numCss">{{ item.count }}</span>个</div>
                        </div>
                        <div class="itemC centerMsg">
                            <el-progress type="circle" :stroke-width="15" :percentage="item.yesterDay" />
                        </div>
                        <div class="itemB ">
                           <Calendar :data="item.data"/>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="TB-right CB-right">
                <div class="titleBox">
                    <img src="../../assets/siteImg/title.png">
                    站点预警概览
                </div>
                <div class="titleMsgBox">
                    <div class="warningBox">
                        <div class="warningItem bgD">
                            <img src="../../assets/siteImg/D.png">
                            <div class="warningmsg">
                                <div>当天预警</div>
                                <div><span class="spanD">1</span>条</div>
                            </div>
                        </div>
                        <div class="warningItem bgW">
                            <img src="../../assets/siteImg/W.png">
                            <div class="warningmsg">
                                <div>本周预警</div>
                                <div><span class="spanW">1</span>条</div>
                            </div>
                        </div>
                        <div class="warningItem bgM">
                            <img src="../../assets/siteImg/M.png">
                            <div class="warningmsg">
                                <div>本月预警</div>
                                <div><span class="spanM">1</span>条</div>
                            </div>
                        </div>
                    </div>
                    <div class="warningChart">
                        <pieChart :data="warningData" :color="['#5092FE','#FF4545']"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottomBox">
            <div class="titleBox">
                <img src="../../assets/siteImg/title.png">
                站点监测环境风险统计
            </div>
            <div class="titleMsgBox BB-main">
                <div class="BB-Left">
                    <pieChart :data="senerData" :color="['#00B286','#FFF32F','#E48C02','#F80000']"/>
                </div>
                <div class="BB-Right">
                    <barChart :data="barData"/>
                </div>
            </div>
        </div>
       
    </div>
</template>
<script>
import Calendar from './modules/Calendar.vue'
import pieChart from './modules/piechart.vue'
import barChart from './modules/barChart.vue'
import mapMode from '@/components/leaflet/index.vue'
import { site_contTimes, site_envMap, site_weatherData, site_envList, site_countZone } from '@/api/site-home.js'
import { mapGetters } from 'vuex'
import moment from 'moment'
import md5 from 'js-md5'
export default{
    components:{
        Calendar,
        pieChart,
        barChart,
        mapMode
    },
    computed:{
        ...mapGetters(['siteId','user'])
    },
    data(){
        return{
            siteData:{},
            museumName:'',
            weatherData:{},
            dayCount:0,
            warningData:[
                { value: 5, name: '已处理' },
                { value: 1, name: '未处理' },
            ],
            senerData:[
                { value: 0, name: '优' },
                { value: 0, name: '良' },
                { value: 0, name: '中' },
                { value: 0, name: '差' },
            ],
            barData:{
                excellent:[],
                good:[],
                average:[],
                poor:[],
            },
            baseDataList:[
                {key:'01',name:'湿度', unit:'%',data:[]},
                {key:'02',name:'温度', unit:'℃',data:[]},
                {key:'03',name:'二氧化碳', unit:'ppm',data:[]},
                {key:'04',name:'光照度', unit:'lx',data:[]},
                {key:'05',name:'紫外辐射强度', unit:'uv',data:[]},
                {key:'06',name:'有机挥发物总量VOC（异丁烯）', unit:'ppm',data:[]}
            ],
        }
    },
    mounted(){
        this.getMap()
        this.siteData = this.findNameById(this.siteId, [this.user.zoneTree])
        if(this.siteData.city){
            let query3 = {
                appid:window.g.weatherAppid,
                sign:md5(`${window.g.weatherAppid}&${moment().valueOf()}&${window.g.weatherSecret}`),
                timestamp:moment().valueOf(),
                area:this.siteData.city,
            }
            site_weatherData(query3).then(r=>{
                this.weatherData = r.data
            })
        }
        
    },
    methods:{
        moment,
        getMap(){
            let minQuery = {
                'siteId':this.siteId,
                'envid':this.siteId
            }
            site_envMap(minQuery).then(res=>{
                let data = res.data.envs
                let src = `${window.g.imgPath}${res.data.layoutUrl}`
                let line = []
                data.forEach(el => {
                    let obj = {
                        type:'polygon',
                        points:JSON.parse(el.latlngs),
                        name:el.envName,
                        envId:el.envId 
                    }
                    line.push(obj)
                });
                this.$refs.mapMode.init(src, line)
            })
            let minQuery2 = {
                'siteId':this.siteId,
                'envId':this.siteId,
                'isSelf':true,
                'isTree':true
            }
            site_envList(minQuery2).then(res=>{
                this.museumName = res.data[0].envName
            })
            let query2 = {
                'siteid':this.siteId,
            }
            site_contTimes(query2).then(r=>{
                this.dayCount = r.data.samples
                let counts = r.data.counts
                let qualify = r.data.qualify
                this.baseDataList = this.baseDataList.map(i=>{
                    i['count'] = counts[i.key]
                    i['data'] = qualify[i.key].map(j=>{
                        return [j.daytime, j.qualify]
                    })
                    i['yesterDay'] = Number(qualify[`${i.key}-Y`])
                    return i
                })
            })
            let query3 = {
                zoneid:this.siteId
            }
            site_countZone(query3).then(r=>{
                let all = r.data.all
                this.senerData[0].value = all.excellent
                this.senerData[1].value = all.good
                this.senerData[2].value = all.average
                this.senerData[3].value = all.poor

                let data = r.data
                this.barData.excellent = [data[12].excellent, data[11].excellent, data[10].excellent]
                this.barData.good = [data[12].good, data[11].good, data[10].good]
                this.barData.average = [data[12].average, data[11].average, data[10].average]
                this.barData.poor = [data[12].poor, data[11].poor, data[10].poor]
            })
        },
        mapEvent(e){
            if(e.type == 'dblclick'){
                this.$router.push({
                    path:'/inside/siteFloor',
                    query:{
                        envId:e.target.options.userData.envId
                    }
                })
            }
        },

        
        findNameById(id, data) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].code == id) {
                    return data[i];  //名称
                } else if (data[i].children) {
                    const result = this.findNameById(id, data[i].children);
                    if (result) {
                        return result;
                    }
                }
            }
            return null;
        }
 
  
    }
}
</script>
<style lang="scss" scoped>
.pageBox{
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}
.topBox{
    width: 100%;
    height:700px;
    display: flex;
    justify-content: space-between;
    .imgBox{
        background-image: url('../../assets/siteImg/posibg.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
    .weather{
        width: 100%;
        height: 200px;
        pointer-events: none;
        background-color: #a7d8ff;
    }
    .textBox{
        padding: 30px 30px;
        line-height: 30px;
    }
    .msgBox{
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        line-height: 30px;
    }
}
.TB-left{
    width:72%;
    height: 100%;
    background-color: #fff;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
}
.TB-right{
    width:calc(28% - 20px);
    height: 100%;
    background-color: #fff;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
}
.titleMsgBox{
    width: 100%;
    height: calc(100% - 60px);
}
.titleBox{
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #CFCFCF;
    box-sizing: border-box;
    padding:0px 20px;
    margin-bottom: 10px;
    img{
        width: 20px;
        height: 30px;
        object-fit: contain;
        vertical-align: middle;
    }
}
.centerBox{
    width: 100%;
    height: 460px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .CB-left{
        box-sizing: border-box;
        .CB-main{
            display: flex;
            justify-content: center;
            align-items: center;
            .main_Item{
                width: 180px;
                height: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: column;
                .centerMsg{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                }
                .itemT{
                    width: 100%;
                    height:70px;
                    border-bottom:1px solid #CFCFCF;
                    box-sizing: border-box;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    .countBox{
                        background: linear-gradient(0deg, #3D58E0, #4AB0FF, #3D58E0);
                        border: 1px solid #5092FE;
                        border-radius: 4px;
                        color: #fff;
                        padding:3px 10px;
                    }
                    .numCss{
                        color: #44aeff;
                        font-size: 20px;
                    }
                }
                .itemC{
                    width: 100%;
                    height:calc(100% - 250px); 
                    border-bottom:1px solid #CFCFCF;
                    box-sizing: border-box;
                }
                .itemB{
                    width: 100%;
                    height:180px; 
                }
            }
        }
    }
    .CB-right{
        box-sizing: border-box;
        .warningBox{
            width: 100%;
            height: 70px;
            margin-bottom: 20px;
            display: flex;
            padding: 0px 20px;
            box-sizing:border-box;
            justify-content: space-between;
            .warningItem{
                width: calc(33% - 5px);
                height: 100%;
                background-color: #4AB0FF;
                display: flex;
                justify-content: space-around;
                align-items: center;
                img{
                    width: 50px;
                    height: 50px;
                    object-fit: contain;
                }
                .warningmsg{
                    height: 50px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    span{
                        font-size: 20px;
                    }
                    .spanD{
                        color: #5092FE;
                    }
                    .spanW{
                        color: #FE9551;
                    }
                    .spanM{
                        color: #FF4545;
                    }
                }
            }
            .bgD{
                background: #ECF0F7;
                box-shadow: 0px 2px 0px 0px #458BFF;
                border-radius: 10px;
            }
            .bgW{
                background: #ECF0F7;
                box-shadow: 0px 2px 0px 0px #FF8F45;
                border-radius: 10px;
            }
            .bgM{
                background: #ECF0F7;
                box-shadow: 0px 2px 0px 0px #FF4545;
                border-radius: 10px;
            }
        }
        .warningChart{
            width:100%;
            height:  calc(100% - 90px);
        }
    }
}
.bottomBox{
    width: 100%;
    height: 460px;
    margin-top: 20px;
    background-color: #fff;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    .BB-main{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .BB-Left{
            width:40%;
            height: 100%;
        }
        .BB-Right{
            width: calc(60% - 30px);
            height: 100%;
        }
    }
}


</style>