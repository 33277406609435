<template>
    <div :class="className" :style="{height:height,width:width}" />
  </template>
  <script>
  import * as echarts from 'echarts'
  import "echarts-gl";
  import { debounce } from '@/utils'
  import { markRaw } from 'vue'
  import moment from 'moment'
  export default {
    props: {
      className: {
        type: String,
        default: 'chart'
      },
      width: {
        type: String,
        default: '100%'
      },
      height: {
        type: String,
        default: '100%'
      },
      data: {
        type: Array,
        default: ()=>{
          return []
        }
      },
      units:{
        type:String
      },
    },

    data() {
      return {
        chart:null
      }
    },
    watch:{
      'data':{
        handler() {
           this.refreshData()
        },
        deep:true,
      },
    },
    mounted() {
      this.refreshData()
      this.__resizeHandler = debounce(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 500)
      window.addEventListener('resize', this.__resizeHandler)
    },
    beforeUnmount() {
      if (!this.chart) {
        return
      }
      window.removeEventListener('resize', this.__resizeHandler)
      this.chart.dispose()
      this.chart = null
    },
    methods: {
      initChart() {
          this.chart = markRaw(echarts.init(this.$el))
          this.setOption()
      },
      setOption(){
          let _this = this
          this.chart.setOption({
            tooltip: {
                position: 'top',
                formatter: function (p) {
                    const format = echarts.time.format(p.data[0], '{yyyy}-{MM}-{dd}', false);
                    return format + ': ' + p.data[1]+'%';
                }
            },
            visualMap: {
                type: 'continuous', // 类型为分段型
                show: false,
                min:0,
                max:100,
                inRange: {
                  color: ['#ff0707', '#25df1a'],
                },
            },
            calendar: {
                orient: 'vertical',
                range: moment().format('YYYY-MM'),
                yearLabel: {
                    show:false
                },
                monthLabel: {
                    show:false
                },
                dayLabel: {
                    show:false
                },
                itemStyle:{
                    color:'#999'
                },
                top: 'center',
                left: 'center',
            },
            series: {
                type: 'heatmap',
                coordinateSystem: 'calendar',
                data: this.data
            }
          })    
      },
      getVirtualData(year) {
        const date = +echarts.time.parse(year + '-01-01');
        const end = +echarts.time.parse(year + '-12-31');
        const dayTime = 3600 * 24 * 1000;
        const data = [];
        for (let time = date; time <= end; time += dayTime) {
            data.push([
            echarts.time.format(time, '{yyyy}-{MM}-{dd}', false),
            Math.floor(Math.random() * 100)
            ]);
        }
        return data;
      },
      refreshData(){
          //刷新数据
          if(this.chart){
            this.setOption();  
          }else{
            this.initChart()
          }  
      }
    }
  }
  
  </script>