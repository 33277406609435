import request from '@/utils/request'

export function site_envList(data) { //查询保存空间(环境)数据
  return request({
    url: '/site/data/envList',
    method: 'get',
    params:data
  })
}

export function site_envMap(data) { //获取指定环境下的设备/子环境地图定位布局数据
  return request({
    url: '/site/data/envMap',
    method: 'get',
    params:data
  })
}

export function site_envData(data) { //查询监测站点环境/位置点历史采集数据。
  return request({
    url: '/site/data/envData',
    method: 'get',
    params:data
  })
}

export function site_listDeviceData1(data) { //盒须图
  return request({
    url: '/site/data/listDeviceData1',
    method: 'get',
    params:data
  })
}



export function site_contTimes(data) { //今日采样次数
  return request({
    url: '/site/data/statNum',
    method: 'get',
    params:data
  })
}


export function site_relicList(data) { //获取文物列表
  return request({
    url: '/site/data/relicList',
    method: 'get',
    params:data
  })
}

export function site_countZone(data) { //站点监测环境风险统计
  return request({
    url: '/site/data/countZone',
    method: 'get',
    params:data
  })
}

export function site_relicDetail(data) { //文物详情
  return request({
    url: '/site/data/relicDetail',
    method: 'get',
    params:data
  })
}

export function site_queryScore(data) { //文物风险占比曲线
  return request({
    url: '/site/data/queryScore',
    method: 'get',
    params:data
  })
}

export function site_countExhibitionid(data) { //文物风险占比
  return request({
    url: '/site/data/countExhibitionid',
    method: 'get',
    params:data
  })
}


export function site_relicImg(id) { //文物二维码
  return request({
    url: `/site/data/relicCode?relicId=${id}`,
    method: 'get',
  })
}

//新页面
export function site_getAllShowData(id) { 
  return request({
    url: `/site/data/getAllShowData?code=${id}`,
    method: 'get',
  })
}

export function site_getDynaData(id) { 
  return request({
    url: `/site/data/getDynaData?code=${id}`,
    method: 'get',
  })
}

export function site_weatherData(data) { //天气质量数据
  return request({
    url: window.g.weatherServe + '/v7/weather/now',
    method: 'get',
    params:data
  })
}

export function site_relics(data) { 
  return request({
    url: `/site/data/relics`,
    method: 'get',
    params:data
  })
}

export function site_countRelicNum(data) { //文物风险等级统计
  return request({
    url: `/site/data/countRelicNum`,
    method: 'get',
    params:data
  })
}

export function site_history(data) { //监测环境曲线图
  return request({
    url: `/site/data/history`,
    method: 'get',
    params:data
  })
}







