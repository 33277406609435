<template>
    <div :class="className" :style="{height:height,width:width}" />
  </template>
  <script>
  import * as echarts from 'echarts'
  import { debounce } from '@/utils'
  import { markRaw } from 'vue'
  export default {
    props: {
      className: {
        type: String,
        default: 'chart'
      },
      width: {
        type: String,
        default: '100%'
      },
      height: {
        type: String,
        default: '100%'
      },
      data: {
        type: Array,
        default: ()=>{
          return []
        }
      },
      legend:{
        type:Boolean,
        default:false
      },
      color: {
        type: Array,
        default: ()=>{
          return []
        }
      },
      radius:{
        type:Array,
        default:()=>{
          return ['40%', '70%']
        }
      },
      units:{
        type:String
      },
      label:{
        type:Boolean,
        default:true,
      },
      itemStyle:{
        type:Object,
        default:{
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2     
        }
      }
    },

    data() {
      return {
        chart:null
      }
    },
    watch:{
      'data':{
        handler() {
           this.refreshData()
        },
        deep:true,
      },
    },
    mounted() {
      this.refreshData()
      this.__resizeHandler = debounce(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 500)
      window.addEventListener('resize', this.__resizeHandler)
    },
    beforeUnmount() {
      if (!this.chart) {
        return
      }
      window.removeEventListener('resize', this.__resizeHandler)
      this.chart.dispose()
      this.chart = null
    },
    methods: {
      initChart() {
          this.chart = markRaw(echarts.init(this.$el))
          this.setOption()
      },
      setOption(){
          let _this = this
          this.chart.setOption({
            tooltip: {
                trigger: 'item'
            },
            legend: {
              show:this.legend
            },
            color:this.color,
            series: [
                {
                name: '环境风险统计',
                type: 'pie',
                radius: this.radius,
                avoidLabelOverlap: false,
                itemStyle: this.itemStyle,
                label: {
                    show: this.label,
                    formatter(param) {
                        return param.name + ' (' + param.percent + '%)';
                    }
                },
                labelLine: {
                    show: this.label
                },
                data: this.data
                }
            ]
          })    
      },
      
      refreshData(){
          //刷新数据
          if(this.chart){
            this.setOption();  
          }else{
            this.initChart()
          }  
      }
    }
  }
  
  </script>